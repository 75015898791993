import Axios from "axios";

let urlBase = "";
const MPS_K = "AIzaSyCgKXQ64V9c6MFlb5IM8WVJiXdePUNu4tA";

const getUrlBase = (url = "", forceUrl) => {
  if (
    url.includes("teste.omegaeducacional") ||
    url.includes("teste.ed.")
  ) {
    urlBase = "https://api.teste.omegaeducacional.com/hotsite";
  } else {
    if (process.env.NODE_ENV === "development") {
      urlBase = "http://192.168.5.80:7999";
    }

    if (process.env.NODE_ENV === "test") {
      urlBase = "https://api.teste.omegaeducacional.com";
    }

    if (process.env.NODE_ENV === "production") {
      urlBase = "https://api.omegaeducacional.com";
    }

    urlBase += "/hotsite";
  }

  if (forceUrl) {
    urlBase = url;
  }

  return urlBase;
};

export const getData = async (path, params = undefined, forceUrl = false) => {
  getUrlBase(params && params.url, forceUrl);
  let { data } = await Axios.get(urlBase + path, { params });
  return data;
};

export const getLocalFromLatLng = async (lat="", lng="") => {
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${MPS_K}`
  );

  const data = await response.json();
  if (data && data.results) {
    let address = data.results.find(e => {
      return (e.types.includes("locality"));
    });

    if (!address) {
      address = data.results.find(e => {
        return e.types.includes("administrative_area_level_2");
      });
    }

    if (address) {
      let city = address.address_components.find((cpnt) => {
        return (cpnt.types.includes("locality"));
      });

      if (!city) {
        city = address.address_components.find(cpnt => {
          return cpnt.types.includes("administrative_area_level_2");
        });
      }

      let state = address.address_components.find((cpnt => {
        return cpnt.types.includes("administrative_area_level_1");
      }));

      if (!state) {
        state = address.address_components.find(cpnt => {
          return cpnt.types.includes("country");
        });
      }

      if (city) {
        let resp = city.long_name;
        if (state) {
          resp += " - " + state.short_name;
        }
        return resp;
      } else {
        address.formatted_address;
      }
    }
  }

  return data.results;
};